import withRoot from "./modules/withRoot";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import ProductCategories from "./modules/views/ProductCategories";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import Employees from "./modules/views/Employees/Employees";
import AppAppBar from "./modules/views/AppAppBar";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Slider from "./modules/views/Slider";
import ArticlesNew from "./modules/views/ArticlesNew";
import Icons from "./modules/views/Icons";
import Footer from "./modules/views/Footer";
import AccountingPage from "./AccountingPage";
import Contacts from './Contacts';  

function App({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/accounting">
          <AccountingPage />
        </Route>
        <Route path="/contacts">
          <Contacts />
        </Route>
      </Switch>
    </Router>
  );
}

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      <Icons />
      <ProductHowItWorks />
      <Employees />
      <ArticlesNew />
      <Slider />
      <ProductSmokingHero />
      <Footer />
    </React.Fragment>
  );
}

export default withRoot(withRouter(App));
