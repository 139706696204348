import React from 'react';
import Footer from "./modules/views/Footer";
import AppAppBar from "./modules/views/AppAppBar";
import "./JurisprudencePage.css";
import './modules/views/styles.scss';
import Prod from "./modules/views/ProductHeroCon"
import WorkerAlona from './modules/views/WorkerAlona';
import WorkerMykhailo from './modules/views/WorkerMykhailo';
import WorkerLesya from './modules/views/WorkerLesya';
import WorkerMykola from './modules/views/WorkerMykola';
import WorkerNatalia from './modules/views/WorkerNatalia';
import WorkerOlena from './modules/views/WorkerOlena';
import WorkerEvgeniy from './modules/views/WorkerEvgeniy';
import WorkerNatalia_O from './modules/views/WorkerNatalia_O';
import WorkerTetyana from './modules/views/WorkerTetyana';

const styles = (theme) => ({
  backgroundLight: {
    backgroundColor: theme.palette.secondary.light,
  },
  backgroundMain: {
    backgroundColor: theme.palette.secondary.main,
  },
  backgroundDark: {
    backgroundColor: theme.palette.secondary.dark,
  },
  padding: {
    padding: theme.spacing(1),
  },
});



function Contacts() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Prod/>
      <div className="workers-list">
        <WorkerAlona />
        <WorkerMykhailo />
        <WorkerLesya />
        <WorkerMykola />
        <WorkerNatalia />
        <WorkerOlena />
        <WorkerEvgeniy />
        <WorkerNatalia_O />
        <WorkerTetyana />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Contacts;

