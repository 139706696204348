import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {TiChevronLeftOutline, TiChevronRightOutline} from "react-icons/ti";
import Employee from "./Employee";
import AlenaGoriachaia from "./photos/AlenaGoriachaia.jpg";
import MikhailoGoriachii from "./photos/MikhailoGoriachii.jpg";
import Olesia3 from "./photos/Olesia3.jpg";
import nikolay from "./photos/nikolay.jpg";
import natalia2 from "./photos/natalia2.jpg";
import olena from "./photos/olena.jpg";
import maxim1 from "./photos/maxim1.jpg";
import jenia from "./photos/Jenia.jpg";
import Nata from "./photos/Nata.jpg";
import tania from "./photos/tania.jpg";
import classes from "./Employees.module.scss"
 

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper() {

    const employees = [
        {
            fullName: 'Горяча Альона Юріївна',
            position: 'Керівник. Досвід у сфері транспортної логістики 14 років.',
            phone: '+380966341617',
            img: AlenaGoriachaia
        },
        {
            fullName: 'Субота Михайло Юрійович',
            position: 'Логіст. Готовий вирішити будь-яке ваше питання 24/7. Розмовна англійська, польська.',
            phone: '+380670001028',
            img: MikhailoGoriachii
        },
        {
            fullName: 'Вдовенко Леся Олександрівна',
            position: 'Головний бухгалтер підприємства. Безперебійний та надійний супровід документообігу.',
            phone: '+380960968402',
            img: Olesia3
        },
        {
            fullName: 'Шевченко Микола Григорович',
            position: 'Куратор логістичного відділу.',
            phone: '+380675589292',
            img: nikolay
        },
        {
            fullName: 'Хлівна Наталя Юріївна',
            position: 'Фахівець з транспортування товарів народного споживання',
            phone: '+380670001460',
            img: natalia2
        },
        {
            fullName: 'Кириченко Олена Миколаївна',
            position: 'Керівник відділу експедитування з міжнародних напрямків,\n' +
                'Спеціаліст з транспортування зернових культур',
            phone: '+380675047553',
            img: olena
        },
        {
            fullName: 'Зубач Євгеній Юрійович',
            position: 'Логіст відділу поставок і просто красавчик',
            phone: '+380679461407',
            img: jenia
        },
        {
            fullName: 'Наталія Олександрівна',
            position: 'Помічник головного бухгалтела. Працює творчо, вміє бачити і зочереджуватися на головному',
            phone: '+380638806886',
            img: Nata
        },
        {
            fullName: 'Гурін Тетяна Михайлівна',
            position: 'Помічник головного бухгалтера. Знайде підхід до кожного.',
            phone: '+380673050902',
            img: tania
        }
    ];

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = employees.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box xs={6} sm={4} md={3}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >

                {employees.map(employee => (
                    <Employee user={employee}/>
                ))}

            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {theme.direction === 'rtl' ? (
                            <TiChevronLeftOutline className={classes.nav}/>
                        ) : (
                            <TiChevronRightOutline className={classes.nav}/>
                        )}
                    </Button>
                }
                backButton={
                    <Button onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <TiChevronLeftOutline className={classes.nav}/>
                        ) : (
                            <TiChevronLeftOutline className={classes.nav}/>
                        )}
                    </Button>
                }
            />
        </Box>
    );
}

export default SwipeableTextMobileStepper;

